// src/pages/OrderDetails.tsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';
import { toast } from 'react-toastify';
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import './OrderDetails.scss';
import { getCategoryName } from '../../config/categories';
import { OrderProductStatus } from "./OrderProductStatus";
import { OrderProductNotes } from "./OrderProductNotes";
import { Tooltip } from "../../components/Tooltip";

interface OrderProduct {
  id: number;
  product_id: number;
  product_name: string;
  category: string;
  quantity: number;
  selling_price: number;
  purchase_price: number;
  status: 'not_ordered' | 'ordered' | 'in_stock';
  notes: string | null;
  unit: string;
  price_option?: string;
}

interface Order {
  id: number;
  estimate_id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  discount: number | string;
  total_selling_price: number | string;
  total_purchase_price: number | string;
  customer_name: string;
  customer_street: string;
  customer_city: string;
  customer_postal_code: string;
  customer_phone: string;
  customer_email: string;
  products: OrderProduct[];
  order_number: string | null;
  notes?: string | null;
}

interface Difference {
  added: DifferenceProduct[];
  removed: DifferenceProduct[];
  changed: DifferenceProduct[];
}

interface OrderHistoryEntry {
  id: number;
  order_id: number;
  change_type: string;
  change_details: string;
  created_at: string;
}

interface ProductChange {
  product_name: string;
  quantity: string;
  unit: string;
  oldQuantity: number;
  newQuantity: number;
  category: string;
  product_id: number;
  selling_price: string;
  purchase_price: string;
  dimensions: { width: number; height: number; quantity: number }[];
  oldOption?: string;
  newOption?: string;
  oldSellingPrice?: number;
  newSellingPrice?: number;
  oldPurchasePrice?: number;
  newPurchasePrice?: number;
}

interface DifferenceProduct {
  product_id: number;
  product_name: string;
  quantity: number | string;
  unit: string;
  oldQuantity?: number | string;
  newQuantity?: number | string;
  oldOption?: string;
  newOption?: string;
}

interface EstimateChanges {
  added: ProductChange[];
  removed: ProductChange[];
  changed: ProductChange[];
}

interface OrderNumberChange {
  oldNumber: string | null;
  newNumber: string;
}

interface DiscountChange {
  oldDiscount: string | number;
  newDiscount: string | number;
}

interface HistoryDetails {
  differences?: EstimateChanges;
  discount?: string | DiscountChange;
  orderNumber?: OrderNumberChange;
}

interface CustomerData {
  customer_name: string;
  customer_street: string;
  customer_city: string;
  customer_postal_code: string;
  customer_phone: string;
  customer_email: string;
}

export const OrderDetails: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [orderData, setOrderData] = useState<Order | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [differences, setDifferences] = useState<Difference | null>(null);
  const [isComparing, setIsComparing] = useState(false);
  const [discountChange, setDiscountChange] = useState<DiscountChange | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [isEditingOrderNumber, setIsEditingOrderNumber] = useState(false);
  const [orderHistory, setOrderHistory] = useState<OrderHistoryEntry[]>([]);
  const [isEditingCustomer, setIsEditingCustomer] = useState(false);
  const [customerData, setCustomerData] = useState<CustomerData>({
    customer_name: '',
    customer_street: '',
    customer_city: '',
    customer_postal_code: '',
    customer_phone: '',
    customer_email: ''
  });
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [notes, setNotes] = useState(orderData?.notes || '');

  const handleNotesUpdate = async () => {
    if (!currentUser || notes.length > 2000) return;

    try {
      await axios.put(
        `${config.API_URL}/orders/${orderId}/notes`,
        { notes },
        {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );

      setOrderData(prevData => prevData ? {
        ...prevData,
        notes
      } : null);

      setIsEditingNotes(false);
      toast.success("Uwagi zostały zaktualizowane");
    } catch (err) {
      console.error('Błąd podczas aktualizacji uwag:', err);
      toast.error('Nie udało się zaktualizować uwag');
    }
  };

  const handleCustomerUpdate = async () => {
    if (!currentUser) return;

    try {
      await axios.put(
        `${config.API_URL}/orders/${orderId}/customer`,
        customerData,
        {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );

      setOrderData(prevData => prevData ? {
        ...prevData,
        ...customerData
      } : null);

      setIsEditingCustomer(false);
      toast.success("Dane klienta zostały zaktualizowane");
    } catch (err) {
      console.error('Błąd podczas aktualizacji danych klienta:', err);
      toast.error('Nie udało się zaktualizować danych klienta');
    }
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!currentUser) return;

      try {
        const response = await axios.get<Order>(`${config.API_URL}/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        });

        setOrderData({
          ...response.data,
          products: response.data.products.map(product => ({
            ...product,
            price_option: product.price_option || undefined
          }))
        });
        setOrderNumber(response.data.order_number || response.data.id.toString());
        setNotes(response.data.notes || ''); // Dodaj tę linię
      } catch (err) {
        setError('Nie udało się pobrać szczegółów zamówienia.');
        console.error('Błąd podczas pobierania szczegółów zamówienia:', err);
      } finally {
        setLoading(false);
      }
    };

    const fetchOrderHistory = async () => {
      if (!currentUser) return;

      try {
        const response = await axios.get<OrderHistoryEntry[]>(`${config.API_URL}/orders/${orderId}/history`, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        });
        setOrderHistory(response.data);
      } catch (err) {
        console.error('Błąd podczas pobierania historii zamówienia:', err);
        toast.error('Nie udało się pobrać historii zamówienia.');
      }
    };

    fetchOrderDetails();
    fetchOrderHistory();
  }, [orderId, currentUser]);

  useEffect(() => {
    if (orderData) {
      setCustomerData({
        customer_name: orderData.customer_name || '',
        customer_street: orderData.customer_street || '',
        customer_city: orderData.customer_city || '',
        customer_postal_code: orderData.customer_postal_code || '',
        customer_phone: orderData.customer_phone || '',
        customer_email: orderData.customer_email || ''
      });
    }
  }, [orderData]);

  const handleOrderNumberUpdate = async () => {
    if (!currentUser) return;

    try {
      await axios.put(
        `${config.API_URL}/orders/${orderId}/number`,
        { orderNumber },
        {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );
      toast.success("Numer zamówienia został zaktualizowany.");
      setIsEditingOrderNumber(false);
      // Aktualizuj lokalny stan
      setOrderData(prevData => {
        if (prevData) {
          return {
            ...prevData,
            order_number: orderNumber
          };
        }
        return prevData;
      });
    } catch (err) {
      console.error('Błąd podczas aktualizacji numeru zamówienia:', err);
      toast.error('Nie udało się zaktualizować numeru zamówienia.');
    }
  };

  const handleStatusChange = async (productId: number, newStatus: 'not_ordered' | 'ordered' | 'in_stock') => {
    if (!currentUser) return;

    try {
      await axios.put(
        `${config.API_URL}/orders/${orderId}/products/${productId}/status`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );

      setOrderData(prevData => {
        if (!prevData) return null;
        return {
          ...prevData,
          products: prevData.products.map(product =>
            product.id === productId
              ? { ...product, status: newStatus as OrderProduct['status'] }
              : product
          )
        };
      });

      toast.success("Status produktu został zaktualizowany");
    } catch (err) {
      console.error('Błąd podczas aktualizacji statusu:', err);
      toast.error('Nie udało się zaktualizować statusu produktu');
    }
  };

  const handleNotesChange = async (productId: number, notes: string) => {
    if (!currentUser) return;

    try {
      await axios.put(
        `${config.API_URL}/orders/${orderId}/products/${productId}/notes`,
        { notes },
        {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );

      setOrderData(prevData => {
        if (!prevData) return null;
        return {
          ...prevData,
          products: prevData.products.map(product =>
            product.id === productId ? { ...product, notes } : product
          )
        };
      });

      toast.success("Notatki zostały zaktualizowane");
    } catch (err) {
      console.error('Błąd podczas aktualizacji notatek:', err);
      toast.error('Nie udało się zaktualizować notatek');
    }
  };

  if (loading) return <div>Ładowanie...</div>;
  if (error) return <div>{error}</div>;
  if (!orderData) return <div>Nie znaleziono zamówienia.</div>;

  const groupProductsByCategory = (products: OrderProduct[]) => {
    return products.reduce((acc, product) => {
      const translatedCategory = getCategoryName(product.category);
      if (!acc[translatedCategory]) {
        acc[translatedCategory] = [];
      }
      acc[translatedCategory].push(product);
      return acc;
    }, {} as Record<string, OrderProduct[]>);
  };

  const formatQuantity = (product: OrderProduct): string => {
    const quantity = parseFloat(product.quantity.toString()).toFixed(2);
    return `${quantity} ${product.unit}`;
  };

  const formatPrice = (price: number | string): string => {
    const numPrice = typeof price === 'string' ? parseFloat(price) : price;
    return isNaN(numPrice) ? '0' : Math.round(numPrice).toString();
  };

  const groupedProducts = groupProductsByCategory(orderData.products);

  const compareWithEstimate = async () => {
    if (!currentUser) return;

    setIsComparing(true);
    try {
      const response = await axios.get<{
        hasChanges: boolean;
        differences?: Difference;
        discountChange?: DiscountChange;
      }>(
        `${config.API_URL}/orders/${orderId}/compare`,
        {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );

      if (response.data.hasChanges) {
        setDifferences(response.data.differences || null);
        setDiscountChange(response.data.discountChange || null);
      } else {
        setDifferences(null);
        setDiscountChange(null);
        toast.info("Brak zmian między zamówieniem a aktualną wyceną.");
      }
    } catch (err) {
      console.error('Błąd podczas porównywania zamówienia z wyceną:', err);
      toast.error('Nie udało się porównać zamówienia z wyceną.');
    } finally {
      setIsComparing(false);
    }
  };

  const updateOrderFromEstimate = async () => {
    if (!currentUser || (!differences && !discountChange)) return;

    try {
      const response = await axios.put(
        `${config.API_URL}/orders/${orderId}/update-from-estimate`,
        { differences, discountChange },
        {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );

      if (response.data.order) {
        setOrderData(response.data.order);
      } else {
        // Jeśli response.data.order nie istnieje, pobierz dane zamówienia ponownie
        const updatedOrderResponse = await axios.get<Order>(`${config.API_URL}/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        });
        setOrderData(updatedOrderResponse.data);
      }

      toast.success("Zamówienie zostało zaktualizowane.");
      setDifferences(null);
      setDiscountChange(null);
    } catch (err) {
      console.error('Błąd podczas aktualizacji zamówienia:', err);
      toast.error('Nie udało się zaktualizować zamówienia.');
    }
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const deleteOrder = async () => {
    if (!currentUser || !orderData) return;

    try {
      await axios.delete(`${config.API_URL}/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${await currentUser.getIdToken()}`
        }
      });
      toast.success("Zamówienie zostało usunięte.");
      // navigate(`/estimate-furniture/${orderData.estimate_id}/total`);
      navigate(`/estimate-furniture/${orderData?.estimate_id}`);
    } catch (err) {
      console.error('Błąd podczas usuwania zamówienia:', err);
      toast.error('Nie udało się usunąć zamówienia.');
    } finally {
      closeDeleteModal();
    }
  };

  const DifferencesSummary: React.FC<{
    differences: Difference | null;
    discountChange: DiscountChange | null;
    onUpdate: () => void
  }> = ({ differences, discountChange, onUpdate }) => {
    if (!differences && !discountChange) return null;

    const formatQuantity = (product: DifferenceProduct) => {
      let quantity: number;
      if (typeof product.quantity === 'string') {
        quantity = parseFloat(product.quantity);
      } else {
        quantity = product.quantity;
      }
      return `${quantity.toFixed(2)} ${product.unit || ''}`.trim();
    };

    return (
      <div className="columns is-vcentered box orderDetails__changesBorder my-6">
        <div className="column has-text-centered">
          <h1 className="title is-4">
            <span className="icon">
              <i className="fa-regular fa-bell fa-shake"></i>
            </span>
            <span className="pl-4">
              Wykryto zmiany
            </span>
          </h1>
          <h2 className="subtitle">
            wycena była edytowana
          </h2>
          <button className="button is-warning mt-3 has-text-weight-bold" onClick={onUpdate}>
            Aktualizuj zamówienie
          </button>
        </div>
        <div className="column">
          {differences && differences.added.length > 0 && (
            <div className="p-2">
              <span className="title is-5 has-text-success">Dodane produkty</span>
              <ul>
                {differences.added.map(product => (
                  <li key={`added-${product.product_id}`}>
                    {product.product_name || 'Nieznany produkt'} | dodano <span className="has-text-weight-bold">{formatQuantity(product)}</span>
                    {product.newOption && <span> (Opcja: {product.newOption})</span>}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {differences && differences.removed.length > 0 && (
            <div className="p-2">
              <span className="title is-5 has-text-danger">Usunięte produkty</span>
              <ul>
                {differences.removed.map(product => (
                  <li key={`removed-${product.product_id}`}>{product.product_name || 'Nieznany produkt'}</li>
                ))}
              </ul>
            </div>
          )}
          {differences && differences.changed.length > 0 && (
            <div className="p-2">
              <span className="title is-5 has-text-warning-dark">Zmienione produkty:</span>
              <ul>
                {differences.changed.map(product => (
                  <li key={`changed-${product.product_id}`}>
                    {product.product_name || 'Nieznany produkt'} -
                    Stara ilość: {formatQuantity({ ...product, quantity: product.oldQuantity || 0 })},
                    Nowa ilość: {formatQuantity({ ...product, quantity: product.newQuantity || 0 })}
                    {product.oldOption !== product.newOption && (
                      <>
                        <br />
                        Zmieniono opcję z {product.oldOption || 'brak'} na {product.newOption || 'brak'}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {discountChange && (
            <div className="p-2">
              <span className="title is-5 has-text-info">Zmiana rabatu</span>
              <p>Stary rabat: {discountChange.oldDiscount}%, Nowy rabat: {discountChange.newDiscount}%</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const calculatePriceAfterDiscount = (price: number | string, discount: number | string): number => {
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;
    const numericDiscount = typeof discount === 'string' ? parseFloat(discount) : discount;
    return numericPrice * (1 - numericDiscount / 100);
  };

  const renderHistoryEntry = (entry: OrderHistoryEntry): JSX.Element => {
    const details = typeof entry.change_details === 'string'
      ? JSON.parse(entry.change_details)
      : entry.change_details as HistoryDetails & {
        type?: string;
        changes?: {
          customer_name: string;
          customer_street: string;
          customer_city: string;
          customer_postal_code: string;
          customer_phone: string;
          customer_email: string;
        };
      };

    const renderProductChanges = (products: ProductChange[], action: string) => (
      <div className="content">
        {products.map((item: ProductChange, index: number) => (
          <div key={`${action}-${index}`} className="columns is-mobile is-multiline">
            <div className="column is-narrow">{item.product_name}</div>
            <div className="column">
              <span className={
                action === 'added'
                  ? 'has-text-success'
                  : action === 'removed'
                    ? 'has-text-danger'
                    : action === 'changed'
                      ? 'has-text-info'
                      : ''
              }>
                {action === 'added' && (
                  `Dodano: ${(parseFloat(item.quantity?.toString() || '0')).toFixed(2)} ${item.unit || 'szt'}`
                )}
                {action === 'removed' && 'Usunięto'}
                {action === 'changed' && (
                  <>
                    {item.oldQuantity !== item.newQuantity && (
                      <>
                        Zmieniono ilość z{' '}
                        {(parseFloat(item.oldQuantity?.toString() || '0')).toFixed(2)} {item.unit || 'szt'}{' '}
                        na {(parseFloat(item.newQuantity?.toString() || '0')).toFixed(2)} {item.unit || 'szt'}
                        <br />
                      </>
                    )}
                    {item.oldOption !== item.newOption && (
                      <>
                        Zmieniono opcję z {item.oldOption || 'brak'} na {item.newOption || 'brak'}
                        <br />
                      </>
                    )}
                    {item.oldSellingPrice !== undefined &&
                      item.newSellingPrice !== undefined &&
                      item.oldSellingPrice !== item.newSellingPrice && (
                      <>
                          Zmieniono cenę sprzedaży z{' '}
                        {(parseFloat(item.oldSellingPrice?.toString() || '0')).toFixed(2)} zł na{' '}
                        {(parseFloat(item.newSellingPrice?.toString() || '0')).toFixed(2)} zł
                        <br />
                      </>
                    )}
                    {item.oldPurchasePrice !== undefined &&
                      item.newPurchasePrice !== undefined &&
                      item.oldPurchasePrice !== item.newPurchasePrice && (
                      <>
                          Zmieniono cenę zakupu z{' '}
                        {(parseFloat(item.oldPurchasePrice?.toString() || '0')).toFixed(2)} zł na{' '}
                        {(parseFloat(item.newPurchasePrice?.toString() || '0')).toFixed(2)} zł
                      </>
                    )}
                  </>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    );

    switch (entry.change_type) {
    case 'update_from_estimate':
    case 'products': {
      if (details.differences) {
        const { added, changed, removed } = details.differences;
        return (
          <div className="content">
            {added && added.length > 0 && (
              <div className="p-4">
                <p className="has-text-weight-semibold">Dodane produkty:</p>
                {renderProductChanges(added, 'added')}
              </div>
            )}
            {removed && removed.length > 0 && (
              <div className="p-4">
                <p className="has-text-weight-semibold">Usunięte produkty:</p>
                {renderProductChanges(removed, 'removed')}
              </div>
            )}
            {changed && changed.length > 0 && (
              <div className="p-4">
                <p className="has-text-weight-semibold">Zmienione produkty:</p>
                {renderProductChanges(changed, 'changed')}
              </div>
            )}
          </div>
        );
      }
      return <p>Aktualizacja produktów</p>;
    }
    case 'order_number': {
      if (details.orderNumber) {
        return (
          <div className="p-4">
            <p className="has-text-weight-semibold">Zmiana numeru zamówienia:</p>
            <p>
                z <span className="has-text-weight-bold">{details.orderNumber.oldNumber || 'brak'}</span>{' '}
                na <span className="has-text-weight-bold">{details.orderNumber.newNumber}</span>
            </p>
          </div>
        );
      }
      return <p>Zmiana numeru zamówienia</p>;
    }
    case 'discount': {
      let discountDetails;
      try {
        discountDetails = typeof details === 'string' ? JSON.parse(details) : details;
      } catch (error) {
        console.error('Error parsing discount details:', error);
        return <p>Błąd przetwarzania danych o zmianie rabatu</p>;
      }

      if (discountDetails && 'newDiscount' in discountDetails && 'oldDiscount' in discountDetails) {
        const { newDiscount, oldDiscount } = discountDetails;
        return (
          <div className="p-4">
            <p className="has-text-weight-semibold">Zmiana rabatu:</p>
            <p>
                Zmieniono rabat z{' '}
              <span className="has-text-weight-bold">
                {(parseFloat(oldDiscount?.toString() || '0')).toFixed(2)}%
              </span>{' '}
                na{' '}
              <span className="has-text-weight-bold">
                {(parseFloat(newDiscount?.toString() || '0')).toFixed(2)}%
              </span>
            </p>
          </div>
        );
      }
      return <p>Zmiana rabatu</p>;
    }
    case 'other': {
      if (details.type === 'customer_update') {
        const { changes } = details;
        return (
          <div className="p-4">
            <div className="has-text-weight-semibold mb-2">Aktualizacja danych klienta:</div>
            <div className="content">
              {Object.entries(changes).map(([field, value]) => {
                const fieldNames = {
                  customer_name: 'Imię i nazwisko',
                  customer_street: 'Ulica',
                  customer_city: 'Miasto',
                  customer_postal_code: 'Kod pocztowy',
                  customer_phone: 'Telefon',
                  customer_email: 'Email'
                };
                return (
                  <div key={field} className="mb-1">
                    <strong>{fieldNames[field as keyof typeof fieldNames]}:</strong> {value as string}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
      return <div>Inna zmiana / edycja notatek</div>;
    }
    default:
      return (
        <div className="p-4">
          <div className="has-text-weight-semibold mb-2">Inna zmiana w zamówieniu:</div>
          <div className="content">
            {Object.entries(details).map(([key, value]) => (
              <div key={key} className="mb-1">
                <strong>{key}:</strong> {JSON.stringify(value)}
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="main">
      <div className="columns mt-4 mb-5 is-vcentered">
        <div className="column is-3 m-3 p-5">
          <div className="title is-flex is-align-items-center is-justify-content-center">
            <span className="icon has-text-info-dark">
              <i className="fa-solid fa-paperclip"></i>
            </span>
            <span className="pl-4 has-text-info-dark">
              Zamówienie
            </span>
          </div>
        </div>
        <div className="column box m-3 p-5 has-background-info-dark">
          <div className="has-text-centered">
            <h1 className="title has-text-white mb-1" style={{ wordBreak: 'break-word' }}>
              <span>{orderData.name}</span>
            </h1>
          </div>
          <div className="has-text-centered has-text-white">
            {orderData.description}
          </div>
          <div className="has-text-centered has-text-white pt-3 is-size-3">
            nr {orderData.order_number || orderData.id.toString()}
          </div>
        </div>
        <div className="column is-3 p-3 has-text-centered">
          <div className="column p-1">
            {isEditingOrderNumber ? (
              <div className="field has-addons is-justify-content-center">
                <div className="control" style={{ width: '150px' }}>
                  <input
                    type="text"
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                    className="input"
                  />
                </div>
                <div className="control">
                  <button onClick={handleOrderNumberUpdate} className="button is-primary">
                    Zapisz
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <button onClick={() => setIsEditingOrderNumber(true)} className="button">
                  <span className="icon">
                    <i className="fas fa-pencil-alt"></i>
                  </span>
                  <span>Edytuj nr zamówienia</span>
                </button>
                {' '}
                <Tooltip
                  content="Możesz ustawić własny numer zamówienia."
                  iconClassName="fa-regular fa-circle-question"
                />
              </div>
            )}
          </div>
          <div className="column p-1">
            <button
              className={`button ${isComparing ? 'is-loading' : ''}`}
              onClick={compareWithEstimate}
              disabled={isComparing}
              title="Sprawdź czy zamówienie zgadza się z aktualną wyceną"
            >
              <span className="icon">
                <i className="fa-solid fa-list-check"></i>
              </span>
              <span>
                Porównaj z wyceną
              </span>
            </button>
            {' '}
            <Tooltip
              content="Jeżeli wycena była edytowana to zmiany zostaną wykryte i wprowadzone do zamówienia jeżeli będziesz chciał."
              iconClassName="fa-regular fa-circle-question"
            />
          </div>
          <div className="column p-1">
            <button className="button" onClick={openDeleteModal} title="Usuń to zamówienie (nie usuwa wyceny)">
              <span className="icon">
                <i className="fas fa-trash-alt"></i>
              </span>
              <span>
                Usuń zamówienie
              </span>
            </button>
            {' '}
            <Tooltip
              content="Usuwa zamówienie, ale nie wycenę."
              iconClassName="fa-regular fa-circle-question"
            />
          </div>
          <div className="column p-1">
            <button
              onClick={() => navigate(`/estimate-furniture/${orderData?.estimate_id}`)}
              className="button is-primary"
              title="Przejdź do edycji wyceny"
            >
              <span className="icon">
                <i className="fa-regular fa-folder"></i>
              </span>
              <span>
                Wróć do wyceny
              </span>
              <span className="icon">
                <i className="fa-solid fa-arrow-right"></i>
              </span>
            </button>
            {' '}
            <Tooltip
              content="Możesz wrócić do wyceny, aby wprowadzić zmiany. Potem użyj przycisku PORÓWNAJ, aby zaktualizować zamówienie."
              iconClassName="fa-regular fa-circle-question"
            />
          </div>
        </div>
      </div>

      {(differences || discountChange) &&
        <DifferencesSummary
          differences={differences}
          discountChange={discountChange}
          onUpdate={updateOrderFromEstimate}
        />
      }

      <div className="orderDetails mb-5">
        <div className="orderDetails__bg"></div>
        <div className="orderDetails__content">
          <div className="columns">
            <div className="box column m-3 p-5">
              <h2 className="title is-4">Informacje ogólne</h2>
              <p>
                <strong>Numer zamówienia:</strong>{' '}
                {orderData.order_number || orderData.id.toString()}
              </p>
              <p><strong>Nazwa wyceny:</strong> {orderData.name}</p>
              <p><strong>Opis:</strong> {orderData.description}</p>
              <p><strong>Data utworzenia:</strong> {new Date(orderData.created_at).toLocaleString()}</p>
              <p><strong>Ostatnia aktualizacja:</strong> {new Date(orderData.updated_at).toLocaleString()}</p>
              <p><strong>Rabat:</strong> {formatPrice(orderData.discount)}%</p>
              <p><strong>Suma sprzedaży:</strong> {formatPrice(orderData.total_selling_price)} zł</p>
              {parseFloat(orderData.discount.toString()) > 0 && (
                <p className="has-text-danger is-size-5">
                  <strong className="has-text-danger">Suma sprzedaży po rabacie:</strong>{' '}
                  <span className="has-text-weight-bold">
                    {formatPrice(calculatePriceAfterDiscount(orderData.total_selling_price, orderData.discount).toString())} zł
                  </span>
                </p>
              )}
              <p><strong>Koszt wykonania:</strong> {formatPrice(orderData.total_purchase_price)} zł</p>
            </div>

            <div className="box column m-3 p-5">
              <div className="is-flex is-justify-content-space-between">
                <h2 className="title is-4">Dane klienta</h2>
                <button
                  className="button"
                  onClick={() => setIsEditingCustomer(!isEditingCustomer)}
                >
                  <span className="icon">
                    <i className={`fas ${isEditingCustomer ? 'fa-times' : 'fa-pencil-alt'}`}></i>
                  </span>
                  <span>{isEditingCustomer ? 'Anuluj' : 'Edytuj'}</span>
                </button>
              </div>

              {isEditingCustomer ? (
                <div className="field">
                  <label className="label">Imię i nazwisko</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      value={customerData.customer_name}
                      onChange={(e) => setCustomerData({ ...customerData, customer_name: e.target.value })}
                    />
                  </div>

                  <label className="label mt-3">Ulica i numer</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      value={customerData.customer_street}
                      onChange={(e) => setCustomerData({ ...customerData, customer_street: e.target.value })}
                    />
                  </div>

                  <div className="columns mt-3">
                    <div className="column is-8">
                      <label className="label">Miasto</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={customerData.customer_city}
                          onChange={(e) => setCustomerData({ ...customerData, customer_city: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="column is-4">
                      <label className="label">Kod pocztowy</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={customerData.customer_postal_code}
                          onChange={(e) => setCustomerData({ ...customerData, customer_postal_code: e.target.value })}
                          placeholder="00-000"
                        />
                      </div>
                    </div>
                  </div>

                  <label className="label mt-3">Telefon</label>
                  <div className="control">
                    <input
                      className="input"
                      type="tel"
                      value={customerData.customer_phone}
                      onChange={(e) => setCustomerData({ ...customerData, customer_phone: e.target.value })}
                    />
                  </div>

                  <label className="label mt-3">Email</label>
                  <div className="control">
                    <input
                      className="input"
                      type="email"
                      value={customerData.customer_email}
                      onChange={(e) => setCustomerData({ ...customerData, customer_email: e.target.value })}
                    />
                  </div>

                  <div className="control mt-4">
                    <button className="button is-primary" onClick={handleCustomerUpdate}>
                      Zapisz zmiany
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <p><strong>Imię i nazwisko:</strong> {orderData.customer_name || ''}</p>
                  <p><strong>Adres:</strong> {orderData.customer_street ? `${orderData.customer_street}, ${orderData.customer_postal_code} ${orderData.customer_city}` : ''}</p>
                  <p><strong>Telefon:</strong> {orderData.customer_phone || ''}</p>
                  <p><strong>Email:</strong> {orderData.customer_email || ''}</p>
                </>
              )}
              <div className="mt-5">
                <div className="is-flex is-justify-content-space-between">
                  <h2 className="title is-4">Notatki</h2>
                  <button
                    className="button"
                    onClick={() => setIsEditingNotes(!isEditingNotes)}
                  >
                    <span className="icon">
                      <i className={`fas ${isEditingNotes ? 'fa-times' : 'fa-pencil-alt'}`}></i>
                    </span>
                    <span>{isEditingNotes ? 'Anuluj' : 'Edytuj notatki'}</span>
                  </button>
                </div>

                {isEditingNotes ? (
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        value={notes}
                        onChange={(e) => {
                          if (e.target.value.length <= 2000) {
                            setNotes(e.target.value);
                          }
                        }}
                        placeholder="Dodaj uwagi do zamówienia..."
                        rows={4}
                        maxLength={2000}
                      />
                    </div>
                    <p className="help has-text-right">
                      {notes.length}/2000 znaków
                    </p>
                    <div className="control mt-4">
                      <button
                        className="button is-primary"
                        onClick={handleNotesUpdate}
                        disabled={notes.length > 2000}
                      >
                        Zapisz uwagi
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="content">
                    {orderData.notes ? (
                      <p className="pre-wrap" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        {orderData.notes}
                      </p>
                    ) : (
                      <p className="has-text-grey-light">Brak notatek. Może warto coś zanotować?</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box">
        {Object.entries(groupedProducts).map(([category, products]) => (
          <div key={category} className="mb-5">
            <h3 className="title is-5 mb-3"></h3>
            <table className="table is-fullwidth products-table">
              <thead>
                <tr className="is-size-6">
                  <th className="is-size-5 has-text-weight-bold">{category.charAt(0).toUpperCase() + category.slice(1)}</th>
                  <th className="is-hidden-mobile">Ilość</th>
                  <th className="is-hidden-mobile">Sprzedaż</th>
                  <th className="is-hidden-mobile">Zakup</th>
                  <th className="has-text-centered">Status</th>
                  <th className="is-hidden-mobile">Notatki</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product: OrderProduct) => (
                  <tr
                    key={product.id}
                    className={
                      product.status === 'in_stock'
                        ? 'has-background-success-light'
                        : product.status === 'ordered'
                          ? 'has-background-info-light'
                          : ''
                    }
                  >
                    <td>
                      {product.product_name}
                      {product.price_option && <span className="has-text-weight-light">&nbsp;(Opcja: {product.price_option})</span>}
                    </td>
                    <td className="is-hidden-mobile">{formatQuantity(product)}</td>
                    <td className="is-hidden-mobile">{formatPrice(product.selling_price)} zł</td>
                    <td className="is-hidden-mobile">{formatPrice(product.purchase_price)} zł</td>
                    <td><OrderProductStatus status={product.status} onChange={(newStatus) => handleStatusChange(product.id, newStatus)} /></td>
                    <td className="is-hidden-mobile"><OrderProductNotes notes={product.notes} onChange={(notes) => handleNotesChange(product.id, notes)} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
      <div className="mt-6 p-4">
        <h2 className="title is-3">Historia zmian</h2>
        {orderHistory.length > 0 ? (
          orderHistory.map((entry, index) => (
            <div key={index} className="mb-5">
              <div className="pb-0">
                <span className="icon has-text-info">
                  <i className="fa-regular fa-bell"></i>
                </span>
                <span className="has-text-weight-bold has-text-info">
                  {format(new Date(entry.created_at), 'dd.MM.yyyy, HH:mm:ss', { locale: pl })}
                </span>
              </div>
              {renderHistoryEntry(entry)}
            </div>
          ))
        ) : (
          <p>Brak historii zmian.</p>
        )}
      </div>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={deleteOrder}
        message={
          <div className="content">
            <p className="has-text-weight-bold is-size-5">
              Czy na pewno chcesz usunąć to zamówienie?
            </p>
            <p className="is-size-6">
              <span className="icon">
                <i className="fa-solid fa-folder-open"></i>
              </span>
              <span className="pl-1">
                Wycena nie zostanie usunięta.
              </span>
            </p>
            <p className="has-text-danger">
              Tej operacji nie można cofnąć.
            </p>
          </div>
        } />
    </div>
  );
};
