import React from "react";
import './Footer.scss';
import { LanguageSelect } from "../LanguageSelect";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { currentVersion } from "../../utils/currentVersion";

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Wersja desktopowa */}
      <footer className="footer pb-5 mb-0 columns has-background-light mt-6 is-hidden-touch">
        {/* Tutaj cała oryginalna zawartość stopki */}
        <div className="column content">
          <h4>Informacje</h4>
          <p><Link to="/terms-of-service">Regulamin</Link></p>
          <p><Link to="/privacy-policy">Polityka prywatności i cookies</Link></p>
          <p><Link to="/changelog">Historia aktualizacji</Link></p>
          <p>
            <Link to="https://www.facebook.com/goestimapl" target="_blank" rel="noreferrer">
              <span className="icon has-text-grey">
                <i className="fab fa-facebook-square fa-2x"></i>
              </span>
            </Link>
          </p>
        </div>
        <div className="column content">
          <h4>Kontakt</h4>
          <p>
            <span className="icon"><i className="fa-solid fa-phone"></i></span>{' '}
            <a href="tel:451261661">451 261 661</a>
          </p>
          <p>
            <span className="icon"><i className="fa-brands fa-whatsapp"></i></span>{' '}
            <a href="https://api.whatsapp.com/send?phone=451261661">Wyślij wiadomość</a>
          </p>
          <p>
            <span className="icon"><i className="fa-regular fa-envelope"></i></span>{' '}
            <a href="mailto:janiewiczk@gmail.com">janiewiczk@gmail.com</a>
          </p>
        </div>
        <div className="column content">
          <h4>Baza wiedzy</h4>
          <Link to="/help">Pomoc</Link>
          <br />
          <Link to="/articles">Artykuły</Link>
          <div className="is-hidden">
            <h4 className="pt-4">Język strony</h4>
            <p className="is-size-7">(w trakcie realizacji)</p>
            <LanguageSelect />
          </div>
        </div>
        <div className="column content">
          <h4>GoEstima {currentVersion}</h4>
          <span className="icon"><i className="fa-regular fa-copyright"></i></span>
          2024 - {new Date().getFullYear()} Janiewicz&apos;s
          <br />
          Krzysztof Janiewicz
          <br /><br />
          {t('footer-all-rights-reserved')}
        </div>
      </footer>

      {/* Wersja mobilna - uproszczona */}
      <footer className="footer py-4 mt-6 has-background-light is-hidden-desktop">
        <div className="content has-text-centered">
          <div className="mb-3">
            <Link to="/help" className="mr-3">Pomoc</Link>
            <Link to="/terms-of-service" className="mr-3">Regulamin</Link>
            <Link to="/privacy-policy">Polityka prywatności</Link>
          </div>
          <div className="mb-3">
            <a href="tel:451261661" className="mr-3">
              <span className="icon"><i className="fa-solid fa-phone"></i></span>
            </a>
            <a href="https://api.whatsapp.com/send?phone=451261661" className="mr-3">
              <span className="icon"><i className="fa-brands fa-whatsapp"></i></span>
            </a>
            <Link to="https://www.facebook.com/goestimapl" target="_blank" rel="noreferrer">
              <span className="icon has-text-grey">
                <i className="fab fa-facebook-square"></i>
              </span>
            </Link>
          </div>
          <div className="is-size-7">
            <span className="icon"><i className="fa-regular fa-copyright"></i></span>
            2024 - {new Date().getFullYear()} Janiewicz&apos;s
          </div>
        </div>
      </footer>
    </>
  );
};
