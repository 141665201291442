import React from 'react';
import './EstimateList.scss';
import { Tooltip } from "../Tooltip";
import { Link, useNavigate } from "react-router-dom";

interface Estimate {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  isLocked: boolean;
  hasOrder: boolean;
  orderId?: number; // Dodajemy opcjonalne pole orderId
}

interface EstimateListProps {
  estimates: Estimate[];
  onEdit: (estimate: Estimate) => void;
  onDelete: (id: number) => void;
  onClone: (id: number) => void;
  onSort: (sortBy: string) => void;
  sortBy: string;
  sortOrder: 'ASC' | 'DESC';
  onToggleLock: (id: number) => void;
  isLoading: boolean;
  isLoadingMore: boolean; // nowy prop
  hasMore: boolean;
  onLoadMore: () => void;
}

export const EstimateList: React.FC<EstimateListProps> = ({
  estimates,
  onDelete,
  onClone,
  onSort,
  sortBy,
  sortOrder,
  onToggleLock,
  isLoading,
  hasMore,
  onLoadMore,
  isLoadingMore,
}) => {
  const navigate = useNavigate();
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('pl-PL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const renderEmptyState = () => (
    <div className="box has-text-centered p-6 m-6">
      <p className="is-size-4">
        <Tooltip
          content="Kliknij ikonę folderu ze znakiem +, na górze strony, aby dodać nową wycenę."
          iconClassName="fa-regular fa-circle-question"
        />
        {' '}
        Nie znalezniono wycen.
      </p>
    </div>
  );

  const sortButtonStyle: React.CSSProperties = {
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    width: '100%',
    textAlign: 'left',
  };

  const truncateText = (text: string, maxLength = 50): string => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const handleRowClick = (estimate: Estimate, event: React.MouseEvent) => {
    // Sprawdzamy, czy kliknięcie nie było na przycisku
    if (!(event.target as HTMLElement).closest('button, a')) {
      navigate(`/estimate-furniture/${estimate.id}`);
    }
  };

  return (
    <div className="content pt-5">
      <h1 className="title has-text-centered">
        <span className="icon has-text-primary">
          <i className="fa-regular fa-folder"></i>
        </span>
        <span className="pl-3">Wyceny</span>
      </h1>

      {estimates.length === 0 ? (
        renderEmptyState()
      ) : (
        <div className="table-container mt-5 box">
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr className="has-background-white">
                <th className="is-hidden-touch has-text-centered" style={{ width: '5%' }}>
                  <Tooltip
                    content="Kliknij na nagłówek kolumny, aby dowolnie posortować listę. Możesz sortować wg nazwy, daty utworzenia i daty aktualizacji."
                    iconClassName="fa-regular fa-circle-question"
                  />
                </th>
                <th className="is-three-fifths-mobile" style={{ width: '45%' }}>
                  <button
                    className="is-size-6"
                    onClick={() => onSort('name')}
                    style={sortButtonStyle}
                  >
                    <span className="has-text-weight-bold">Nazwa</span>
                    {' '}
                    {sortBy === 'name' && <span className="icon"><i className={`fas fa-sort-${sortOrder === 'ASC' ? 'up' : 'down'}`}></i></span>}
                  </button>
                </th>
                <th style={{ width: '15%' }} className="is-hidden-touch">
                  <button
                    className="is-size-6"
                    onClick={() => onSort('created_at')}
                    style={sortButtonStyle}
                  >
                    <span className="icon">
                      <i className="fas fa-calendar-plus"></i>
                    </span>
                    {' '}
                    <span className="has-text-weight-bold">Utworzona</span>
                    {sortBy === 'created_at' && <span className="icon"><i className={`fas fa-sort-${sortOrder === 'ASC' ? 'up' : 'down'}`}></i></span>}
                  </button>
                </th>
                <th style={{ width: '15%' }} className="is-hidden-touch">
                  <button
                    className="is-size-6"
                    onClick={() => onSort('updated_at')}
                    style={sortButtonStyle}
                  >
                    <span className="icon">
                      <i className="fas fa-calendar-check"></i>
                    </span>
                    {' '}
                    <span className="has-text-weight-bold">Aktualizacja</span>
                    {sortBy === 'updated_at' && <span className="icon"><i className={`fas fa-sort-${sortOrder === 'ASC' ? 'up' : 'down'}`}></i></span>}
                  </button>
                </th>
                <th className="has-text-right is-two-fifths-mobile" style={{ width: '20%' }}>
                  <Tooltip
                    content="
                    Za pomocą poniższych przycisków możesz utworzyć kopię wyceny, usunąć wycenę lub ją zablokować.
                    "
                    iconClassName="fa-regular fa-circle-question"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {estimates.map((estimate) => (
                <tr
                  key={estimate.id}
                  className="estimate-row"
                  onClick={(event) => handleRowClick(estimate, event)}
                >
                  <td className="has-text-centered is-hidden-touch is-vcentered">
                    <span className="icon is-size-4 has-text-primary" style={{ position: 'relative' }}>
                      <i className="fa-regular fa-folder folder-icon folder-closed"></i>
                      <i className="fa-regular has-text-primary fa-folder-open folder-icon folder-open"></i>
                    </span>
                  </td>
                  <td className="is-vcentered">
                    <div className="is-flex is-flex-direction-column pr-3">
                      <div className="is-flex is-align-items-center">
                        <span className="has-text-weight-semibold is-size-6">
                          {truncateText(estimate.name, 60)}
                        </span>
                        {estimate.hasOrder && (
                          <span className="tag is-info is-light ml-2">
                            <span className="icon is-small mr-1">
                              <i className="fa-solid fa-paperclip"></i>
                            </span>
                            Zamówienie
                          </span>
                        )}
                      </div>
                      {estimate.description && (
                        <span className="is-size-7 has-text-grey mt-1">
                          {truncateText(estimate.description, 80)}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="is-size-6 is-hidden-touch has-text-centered is-vcentered">
                    {formatDate(estimate.created_at)}
                  </td>
                  <td className="is-size-6 is-hidden-touch has-text-centered is-vcentered">
                    {formatDate(estimate.updated_at)}
                  </td>
                  <td>
                    <div className="buttons mb-0 mt-2 is-justify-content-flex-end">
                      {estimate.hasOrder && (
                        <Link
                          to={`/orders/${estimate.orderId}`}
                          className="button is-info"
                          title="Przejdź do zamówienia"
                          onClick={(e) => {
                            e.stopPropagation(); // Zatrzymuje propagację eventu
                            if (!estimate.hasOrder) {
                              e.preventDefault();
                            }
                          }}
                        >
                          <span className="icon">
                            <i className="fa-solid fa-paperclip"></i>
                          </span>
                        </Link>
                      )}
                      <button title="Utwórz kopię wyceny" className="button" onClick={() => onClone(estimate.id)}>
                        <span className="icon">
                          <i className="fa-regular fa-copy"></i>
                        </span>
                      </button>
                      <button
                        title="Usuń wycenę"
                        className="button"
                        onClick={() => onDelete(estimate.id)}
                        disabled={estimate.isLocked}
                      >
                        <span className="icon">
                          <i className="fa-regular fa-trash-can"></i>
                        </span>
                      </button>
                      <button
                        title={estimate.isLocked ? "Odblokuj wycenę" : "Zablokuj wycenę"}
                        className={`is-hidden-mobile button ${estimate.isLocked ? 'is-danger' : ''}`}
                        onClick={() => onToggleLock(estimate.id)}
                      >
                        <span className="icon">
                          <i className={`fa-solid ${estimate.isLocked ? 'fa-lock' : 'fa-lock-open'}`}></i>
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <nav className="is-centered" role="navigation" aria-label="pagination">
        {estimates.length > 0 && (
          <div className="has-text-centered mt-5 mb-5">
            {isLoadingMore ? (
              <div className="has-text-centered my-6">
                <span className="icon is-large">
                  <i className="fas fa-spinner fa-pulse fa-2x"></i>
                </span>
              </div>
            ) : (
              hasMore && !isLoading && (
                <button
                  className="button is-primary"
                  onClick={onLoadMore}
                  disabled={isLoading || isLoadingMore}
                >
                  <span className="icon">
                    <i className="fas fa-sync"></i>
                  </span>
                  <span>Wczytaj więcej</span>
                </button>
              )
            )}
          </div>
        )}
      </nav>
    </div>
  );
};
